import React, { useEffect, useState } from "react"
import Layout from "components/common/layout.js"
import bigDot from "images/bigDot.png"
import CheckBox from "components/tools/checkBox.js"
import * as styles from "pages/member/setting/unsubscribe.module.css"
import SettingLayout from "components/member/Setting/settingLayout"
import UnsubscribeCheckBoxContainer from "containers/member/setting/unsubscribeCheckBoxContainer"
import { shallowEqual, useDispatch, useSelector } from "react-redux"
import api from "apis"
import { setProductListAsync } from "store/modules/payInfoReducer"
import { navigate } from "gatsby"
const UnsubscribeRoutinePage = props => {
  const onCheck = ({ target }) => {
    document.getElementsByName("checkbox").forEach(box => {
      box.checked = false
    })
    target.checked = true
  }
  const [scbInfo, setScbInfo] = useState({})
  const dispatch = useDispatch()

  useEffect(() => {
    ;(async () => {
      const scbInfo = await api.getscbDetail()
      setScbInfo(scbInfo)
      await errorHandler(scbInfo)

      dispatch(setProductListAsync())
    })()
  }, [])

  const { list } = useSelector(
    ({ payInfoReducer: { product } }) => product,
    shallowEqual
  )

  const goods = list.get(scbInfo?.goods_no)
  const endDate = goods?.period?.split("\n")[1]

  return (
    <Layout settingHeader>
      <SettingLayout title="구독해지" />

      <div className={styles.con}>
        <img src={bigDot} alt={"titleLogo"} />
        <p style={{ fontWeight: "900", color: "#333", fontSize: "20px" }}>
          피치서가 정기구독을 해지 하시겠어요?
        </p>
        <p style={{ fontSize: "18px", fontWeight: "normal" }}>
          구독을 해지하려면 아래의 사항을 확인해주세요.
        </p>

        <div className={styles.greybox}>
          <div>
            <p className={styles.colon}>:</p> 정기구독은 현 결제주기 마지막 날인{" "}
            {endDate}에 해지됩니다.
          </div>
          <div>
            <p className={styles.colon}>:</p> 김피치님은 피치서가를 {endDate}
            까지 정상적으로 이용할 수 있습니다.
          </div>
          <div>
            <p className={styles.colon}>:</p> 나의 서가에 보관한 모든 콘텐츠는
            서비스 종료일부터 6개월간 유지됩니다.
          </div>
        </div>

        <div className={styles.checkBox}>
          <p style={{ fontSize: "16px", fontWeight: "normal" }}>
            &nbsp;
            <CheckBox id={"CheckUnsubscribe"} className={"circleCheckBox"} />
            &nbsp;구독 해지 사항에 대해 확인했습니다.
          </p>
          <hr></hr>
        </div>
      </div>

      <UnsubscribeCheckBoxContainer
        onCheck={onCheck}
        errorHandler={errorHandler}
        scbInfo={scbInfo}
      />
    </Layout>
  )
}

export default UnsubscribeRoutinePage
const errorHandler = async scbInfo => {
  console.log(scbInfo)
  if (!scbInfo?.isScb) {
    await confirm("현재 구독중이 아닙니다.")
    return true
  } else if (scbInfo?.isRefund) {
    await confirm("관리자가 이미 환불을 진행중입니다.")
    return true
  } else {
    return false
  }
}
